<template>
  <div id="app">
    <Navigation />
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import VueJwtDecode from "vue-jwt-decode";
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import { getHomeForRole } from "@/util";

let parseToken = function(token) {
  /**
   * Checks if token is valid. If it's valid, the payload is returned.
   * Otherwise returns false.
   */
  let tokenData = VueJwtDecode.decode(token);
  const now = new Date().getTime() / 1000;
  if (now > tokenData.exp) {
    // Token expired
    return false;
  }
  return tokenData;
};

export default {
  name: "App",
  components: {
    Navigation
  },
  methods: {
    leaveHandler: function(unloadEvt) {
      // Warn about navigating away if leaveGuard is set.
      if (this.$store.state.leaveGuard) {
        unloadEvt.preventDefault();
      }
    },
    initToken: function() {
      // See if we have a token
      const token = localStorage.getItem("token");
      if (token) {
        let tokenData = parseToken(token);
        if (tokenData) {
          // Token is valid
          // TODO: Refresh tokens would be a nice to have here
          this.$store.commit("setUser", tokenData);
          this.$store.dispatch("getCategories")
        } else {
          // console.log("Token invalid or expired");
          localStorage.removeItem("token");
        }
      }
      //  else {
      //   console.log("No token found");
      // }
    }
  },
  created: function() {
    // Window event handlers
    window.addEventListener("beforeunload", this.leaveHandler);
    // Initialize login token (from localStorage)
    this.initToken();
    this.$store.dispatch("getApiVersion");
    // Redirect based on role (null is an accepted value)
    let role = this.$store.state.user.role;
    let route = getHomeForRole(role);
    this.$router.push(route);
  },
  beforeMounted: function() {},
  mounted: function() {}
};
</script>