/**
 * Constants used througout the application.
 */

const ROLES = {
    ADMIN: "admin",  // Technical admin
    MANAGER: "manager",  // User admin
    SUPPLIER: "supplier",
    AGENCY: "agency",
    PACKAGING: "packaging",
}

export {
    ROLES
}